/* windicss layer base */
*, ::before, ::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}
* {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
}
:root {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
:-moz-ui-invalid {
  box-shadow: none;
}
::moz-focus-inner {
  border-style: none;
  padding: 0;
}
::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
a {
  color: inherit;
  text-decoration: inherit;
}
body {
  margin: 0;
  font-family: inherit;
  line-height: inherit;
}
button {
  text-transform: none;
  background-color: transparent;
  background-image: none;
}
button, [type='button'], [type='reset'], [type='submit'] {
  -webkit-appearance: button;
}
button, [role="button"] {
  cursor: pointer;
}
html {
  -webkit-text-size-adjust: 100%;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  line-height: 1.5;
}
h3, p, h2 {
  margin: 0;
}
h3, h2 {
  font-size: inherit;
  font-weight: inherit;
}
input, button {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 0;
  line-height: inherit;
  color: inherit;
}
img {
  border-style: solid;
  display: block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}
input::placeholder {
  opacity: 1;
  color: #9ca3af;
}
input::webkit-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}
input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}
input:-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}
input::-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
strong {
  font-weight: bolder;
}
table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}
/* windicss layer components */

/* windicss layer utilities */
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-gray-600\/50 {
  --tw-bg-opacity: 0.5;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.bg-dark-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 34, 34, var(--tw-bg-opacity));
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.border-black {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.border-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}
.border-t-gray-100 {
  --tw-border-opacity: 1;
  border-top-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.border-b-gray-100 {
  --tw-border-opacity: 1;
  border-bottom-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.border-t-gray-300 {
  --tw-border-opacity: 1;
  border-top-color: rgba(209, 213, 219, var(--tw-border-opacity));
}
.border-l-black {
  --tw-border-opacity: 1;
  border-left-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
.border-b-black {
  --tw-border-opacity: 1;
  border-bottom-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
.border-b-gray-200 {
  --tw-border-opacity: 1;
  border-bottom-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-sm {
  border-radius: 0.125rem;
}
.border-solid {
  border-style: solid;
}
.border {
  border-width: 1px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-t {
  border-top-width: 1px;
}
.border-l {
  border-left-width: 1px;
}
.cursor-move {
  cursor: move;
}
.cursor-pointer {
  cursor: pointer;
}
.inline {
  display: inline;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.children\:flex > * {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.table {
  display: table;
}
.contents {
  display: contents;
}
.hidden {
  display: none;
}
.\!hidden {
  display: none !important;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.children\:flex-wrap > * {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.items-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.\!items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  -webkit-align-items: flex-start !important;
  align-items: flex-start !important;
}
.items-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.\!items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  -webkit-align-items: center !important;
  align-items: center !important;
}
.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.children\:justify-between > * {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
}
.children\:flex-1 > * {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
}
.flex-none {
  -webkit-box-flex: 0;
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
}
.children\:flex-\[2\] > * {
  -webkit-box-flex: 1;
  -ms-flex: 2;
  -webkit-flex: 2;
  flex: 2;
}
.flex-\[2\] {
  -webkit-box-flex: 1;
  -ms-flex: 2;
  -webkit-flex: 2;
  flex: 2;
}
.children\:flex-\[20\%\] > * {
  -webkit-box-flex: 1;
  -ms-flex: 20%;
  -webkit-flex: 20%;
  flex: 20%;
}
.\!flex-none {
  -webkit-box-flex: 0 !important;
  -ms-flex: none !important;
  -webkit-flex: none !important;
  flex: none !important;
}
.flex-grow {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.flex-shrink-0 {
  -ms-flex-negative: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}
.h-full {
  height: 100%;
}
.h-8 {
  height: 2rem;
}
.\!children\:h-5 > * {
  height: 1.25rem !important;
}
.h-80 {
  height: 20rem;
}
.h-4 {
  height: 1rem;
}
.h-12 {
  height: 3rem;
}
.h-screen {
  height: 100vh;
}
.h-\[30px\] {
  height: 30px;
}
.h-48px {
  height: 48px;
}
.h-\[100vh\] {
  height: 100vh;
}
.\!h-\[200px\] {
  height: 200px !important;
}
.h-\[40px\] {
  height: 40px;
}
.\!h-\[5em\] {
  height: 5em !important;
}
.h-28px {
  height: 28px;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.\!text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}
.children\:leading-12 > * {
  line-height: 3rem;
}
.leading-none {
  line-height: 1;
}
.list-decimal {
  list-style-type: decimal;
}
.\!m-0 {
  margin: 0px !important;
}
.\!my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.\!my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.\!mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.\!mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.\!children\:my-0 > * {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.\!children\:my-2 > * {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.\!mb-0 {
  margin-bottom: 0px !important;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.\!children\:mb-0 > * {
  margin-bottom: 0px !important;
}
.\!mb-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2 {
  margin-left: 0.5rem;
}
.\!children\:mt-2 > * {
  margin-top: 0.5rem !important;
}
.mt-24 {
  margin-top: 6rem;
}
.\!mr-2 {
  margin-right: 0.5rem !important;
}
.\!mt-2 {
  margin-top: 0.5rem !important;
}
.children\:mb-2 > * {
  margin-bottom: 0.5rem;
}
.mt-40 {
  margin-top: 10rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mt-48 {
  margin-top: 12rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.mt-60 {
  margin-top: 15rem;
}
.mt-12 {
  margin-top: 3rem;
}
.mt-8 {
  margin-top: 2rem;
}
.mt-0\.5 {
  margin-top: 0.125rem;
}
.mr-0\.5 {
  margin-right: 0.125rem;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mr-8 {
  margin-right: 2rem;
}
.mr-6 {
  margin-right: 1.5rem;
}
.mr-4 {
  margin-right: 1rem;
}
.\!mt-0 {
  margin-top: 0px !important;
}
.\!mt-6 {
  margin-top: 1.5rem !important;
}
.\!mt-1 {
  margin-top: 0.25rem !important;
}
.children\:mt-1 > * {
  margin-top: 0.25rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.ml-4 {
  margin-left: 1rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.max-h-full {
  max-height: 100%;
}
.max-h-48 {
  max-height: 12rem;
}
.max-h-400px {
  max-height: 400px;
}
.max-h-504px {
  max-height: 504px;
}
.max-h-\[60mm\] {
  max-height: 60mm;
}
.max-h-564px {
  max-height: 564px;
}
.max-w-full {
  max-width: 100%;
}
.max-w-\[65mm\] {
  max-width: 65mm;
}
.max-w-\[140px\] {
  max-width: 140px;
}
.max-w-170px {
  max-width: 170px;
}
.max-w-\[260px\] {
  max-width: 260px;
}
.max-w-\[200px\] {
  max-width: 200px;
}
.max-w-\[850px\] {
  max-width: 850px;
}
.max-w-\[660px\] {
  max-width: 660px;
}
.min-h-0 {
  min-height: 0px;
}
.min-h-full {
  min-height: 100%;
}
.min-h-24 {
  min-height: 6rem;
}
.min-h-\[80px\] {
  min-height: 80px;
}
.\!children\:min-h-\[20px\] > * {
  min-height: 20px !important;
}
.min-h-\[100px\] {
  min-height: 100px;
}
.min-h-80px {
  min-height: 80px;
}
.min-h-\[5em\] {
  min-height: 5em;
}
.min-h-120px {
  min-height: 120px;
}
.min-w-0 {
  min-width: 0px;
}
.min-w-full {
  min-width: 100%;
}
.min-w-20 {
  min-width: 5rem;
}
.min-w-16 {
  min-width: 4rem;
}
.min-w-\[1280px\] {
  min-width: 1280px;
}
.min-w-\[100px\] {
  min-width: 100px;
}
.min-w-38 {
  min-width: 9.5rem;
}
.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}
.opacity-0 {
  opacity: 0;
}
.hover\:opacity-100:hover {
  opacity: 1;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.p-2 {
  padding: 0.5rem;
}
.\!p-0 {
  padding: 0px !important;
}
.p-1 {
  padding: 0.25rem;
}
.p-12 {
  padding: 3rem;
}
.p-4 {
  padding: 1rem;
}
.p-3 {
  padding: 0.75rem;
}
.p-8 {
  padding: 2rem;
}
.\!p-2 {
  padding: 0.5rem !important;
}
.p-52 {
  padding: 13rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.\!px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.\!px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}
.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.\!pb-0 {
  padding-bottom: 0px !important;
}
.pl-1 {
  padding-left: 0.25rem;
}
.pl-3 {
  padding-left: 0.75rem;
}
.\!pt-\[1px\] {
  padding-top: 1px !important;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pl-8 {
  padding-left: 2rem;
}
.pr-12 {
  padding-right: 3rem;
}
.pr-12py-4 {
  padding-right: 1rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pb-1 {
  padding-bottom: 0.25rem;
}
.pt-1 {
  padding-top: 0.25rem;
}
.\.\.\.tab {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}
.tab\.id {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}
.tab {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
}
.left-0 {
  left: 0px;
}
.right-0 {
  right: 0px;
}
.bottom-\[36px\] {
  bottom: 36px;
}
.top-0 {
  top: 0px;
}
.bottom-0 {
  bottom: 0px;
}
.bottom-2 {
  bottom: 0.5rem;
}
.right-2 {
  right: 0.5rem;
}
.top-1 {
  top: 0.25rem;
}
.right-3 {
  right: 0.75rem;
}
.top-3 {
  top: 0.75rem;
}
.resize-y {
  resize: vertical;
}
.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0/0.1),0 2px 4px -2px rgb(0 0 0/0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),0 2px 4px -2px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}
.shadow-gray-200 {
  --tw-shadow-color: rgba(229, 231, 235, 1);
  --tw-shadow: var(--tw-shadow-colored);
}
.table-fixed {
  table-layout: fixed;
}
.text-left {
  text-align: left;
}
.\!text-left {
  text-align: left !important;
}
.text-center {
  text-align: center;
}
.\!text-center {
  text-align: center !important;
}
.text-right {
  text-align: right;
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.\!children\:text-white > * {
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-red-700 {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.overflow-ellipsis {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.underline-black {
  --tw-line-opacity: 1;
  -webkit-text-decoration-color: rgba(0, 0, 0, var(--tw-line-opacity));
  text-decoration-color: rgba(0, 0, 0, var(--tw-line-opacity));
}
.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}
.select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.visible {
  visibility: visible;
}
.whitespace-normal {
  white-space: normal;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.break-all {
  word-break: break-all;
}
.w-full {
  width: 100%;
}
.w-1 {
  width: 0.25rem;
}
.w-24 {
  width: 6rem;
}
.w-16 {
  width: 4rem;
}
.w-48 {
  width: 12rem;
}
.w-72 {
  width: 18rem;
}
.w-56 {
  width: 14rem;
}
.w-12 {
  width: 3rem;
}
.w-60 {
  width: 15rem;
}
.w-36 {
  width: 9rem;
}
.w-44 {
  width: 11rem;
}
.\!w-64 {
  width: 16rem !important;
}
.w-20 {
  width: 5rem;
}
.w-4 {
  width: 1rem;
}
.children\:w-full > * {
  width: 100%;
}
.w-32 {
  width: 8rem;
}
.w-96 {
  width: 24rem;
}
.\!w-48 {
  width: 12rem !important;
}
.w-28 {
  width: 7rem;
}
.w-64 {
  width: 16rem;
}
.w-52 {
  width: 13rem;
}
.\!w-auto {
  width: auto !important;
}
.w-8 {
  width: 2rem;
}
.children\:w-48 > * {
  width: 12rem;
}
.children\:w-52 > * {
  width: 13rem;
}
.w-80 {
  width: 20rem;
}
.w-40 {
  width: 10rem;
}
.w-screen {
  width: 100vw;
}
.w-\[100px\] {
  width: 100px;
}
.w-38 {
  width: 9.5rem;
}
.w-54 {
  width: 13.5rem;
}
.w-42 {
  width: 10.5rem;
}
.w-30 {
  width: 7.5rem;
}
.w-\[100vw\] {
  width: 100vw;
}
.children\:w-38 > * {
  width: 9.5rem;
}
.\!w-38 {
  width: 9.5rem !important;
}
.\!w-18 {
  width: 4.5rem !important;
}
.w-8em {
  width: 8em;
}
.w-100 {
  width: 25rem;
}
.w-\[4em\] {
  width: 4em;
}
.w-90 {
  width: 22.5rem;
}
.w-70 {
  width: 17.5rem;
}
.w-18 {
  width: 4.5rem;
}
.w-58 {
  width: 14.5rem;
}
.w-190 {
  width: 47.5rem;
}
.w-22 {
  width: 5.5rem;
}
.w-34 {
  width: 8.5rem;
}
.w-88 {
  width: 22rem;
}
.w-122 {
  width: 30.5rem;
}
.w-162 {
  width: 40.5rem;
}
.w-160px {
  width: 160px;
}
.w-400px {
  width: 400px;
}
.w-60px {
  width: 60px;
}
.w-140px {
  width: 140px;
}
.w-80px {
  width: 80px;
}
.\!w-30px {
  width: 30px !important;
}
.\!w-150px {
  width: 150px !important;
}
.\!w-60px {
  width: 60px !important;
}
.\!w-80px {
  width: 80px !important;
}
.\!w-200px {
  width: 200px !important;
}
.\!w-90px {
  width: 90px !important;
}
.w-62 {
  width: 15.5rem;
}
.w-46 {
  width: 11.5rem;
}
.w-142 {
  width: 35.5rem;
}
.w-84 {
  width: 21rem;
}
.w-78 {
  width: 19.5rem;
}
.children\:w-58 > * {
  width: 14.5rem;
}
.w-118 {
  width: 29.5rem;
}
.\!w-98 {
  width: 24.5rem !important;
}
.\!w-30 {
  width: 7.5rem !important;
}
.\!w-42 {
  width: 10.5rem !important;
}
.w-170 {
  width: 42.5rem;
}
.w-102 {
  width: 25.5rem;
}
.w-26 {
  width: 6.5rem;
}
.w-82\.5 {
  width: 20.625rem;
}
.w-150px {
  width: 150px;
}
.w-200px {
  width: 200px;
}
.w-\[5em\] {
  width: 5em;
}
.w-40px {
  width: 40px;
}
.w-180px {
  width: 180px;
}
.w-120px {
  width: 120px;
}
.w-20px {
  width: 20px;
}
.w-12em {
  width: 12em;
}
.w-4em {
  width: 4em;
}
.w-50 {
  width: 12.5rem;
}
.w-\[6em\] {
  width: 6em;
}
.w-74 {
  width: 18.5rem;
}
.w-72\.5 {
  width: 18.125rem;
}
.w-46\.5 {
  width: 11.625rem;
}
.w-134 {
  width: 33.5rem;
}
.w-158 {
  width: 39.5rem;
}
.w-6em {
  width: 6em;
}
.w-3em {
  width: 3em;
}
.\!w-88px {
  width: 88px !important;
}
.\!w-100px {
  width: 100px !important;
}
.\!w-4em {
  width: 4em !important;
}
.w-182 {
  width: 45.5rem;
}
.\!w-260px {
  width: 260px !important;
}
.\!w-\[80px\] {
  width: 80px !important;
}
.\!w-70px {
  width: 70px !important;
}
.w-196 {
  width: 49rem;
}
.w-23 {
  width: 5.75rem;
}
.children\:w-30 > * {
  width: 7.5rem;
}
.children\:w-54 > * {
  width: 13.5rem;
}
.w-120 {
  width: 30rem;
}
.z-10 {
  z-index: 10;
}
.gap-2 {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.gap-1 {
  grid-gap: 0.25rem;
  gap: 0.25rem;
}
.gap-4 {
  grid-gap: 1rem;
  gap: 1rem;
}
.children\:gap-2 > * {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.gap-8 {
  grid-gap: 2rem;
  gap: 2rem;
}
.gap-x-2 {
  -webkit-column-gap: 0.5rem;
  -moz-column-gap: 0.5rem;
  grid-column-gap: 0.5rem;
  column-gap: 0.5rem;
}
.gap-x-3 {
  -webkit-column-gap: 0.75rem;
  -moz-column-gap: 0.75rem;
  grid-column-gap: 0.75rem;
  column-gap: 0.75rem;
}
.gap-x-4 {
  -webkit-column-gap: 1rem;
  -moz-column-gap: 1rem;
  grid-column-gap: 1rem;
  column-gap: 1rem;
}
.filter {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}