@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";
@import "~@blueprintjs/select/lib/css/blueprint-select.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "~@blueprintjs/datetime2/lib/css/blueprint-datetime2.css";
@import "~@blueprintjs/table/lib/css/table.css";
@import "~react-resizable/css/styles.css";

.bp4-popover2-transition-container {
  z-index: 1000;
}

.react-resizable-handle {
  z-index: 100;
}

.bp4-toast-container {
  z-index: 10001;
}

#alert > .bp4-portal > .bp4-overlay {
  z-index: 10000;
}
